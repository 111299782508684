import { MessageBar, MessageBarType, Stack } from "@fluentui/react";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import Success from "./components/Success";

function App() {
  const [announcement, setAnnouncement] = useState<string[]>([]);

  async function fetchData() {
    await new Promise(r => setTimeout(r, 5000));

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/v1/announcement?target=C&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
        {
          redirect: 'follow',
        }
      );
      if (response.status === 429) {
        throw new Error('TooManyRequests');
      }
      if (response.ok) {
        const data: string[] = (await response.json()) as string[];
        setAnnouncement(data);
      }
    } catch (error: any) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Stack horizontalAlign="center">
        {announcement?.map(a =>
          <MessageBar messageBarType={MessageBarType.info}>{a}</MessageBar>
        )}
      </Stack>
      <BrowserRouter>
        <Routes>
          <Route path="id/:id" element={<Home />} />
          <Route path=":id" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/success" element={<Success />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
