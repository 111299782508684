import { Dropdown, IDropdownOption, Label, Stack, TextField } from '@fluentui/react';
import { useEffect, useState } from 'react'

export interface IField {
  name: string;
  fieldType: string;
  label: string;
  lookupList?: string;
}
interface ILookupValue {
  key: string;
  value: string;
}
interface ILookup {
  name: string;
  values: ILookupValue[];
}
interface IValidation {
  field: string;
  rules: string[];
}
export interface IMetadata {
  lookups: ILookup[];
  fields: IField[];
  validations: IValidation[];
}

export interface IOtherInfoProps {
  metadataResponse?: any;
  values?: string;
}
export const OtherInfo = (params: IOtherInfoProps) => {
  const [metadata, setMetadata] = useState<IMetadata>();
  const [values, setValues] = useState();

  const fetchData = async () => {
    if (!params.metadataResponse) {
      return;
    }
    const metadata = params.metadataResponse as IMetadata;
    console.log(metadata);
    setMetadata(metadata);
    if (params.values && params.values !== '') {
      setValues(JSON.parse(params.values ?? ''));
    }
  }

  useEffect(() => {
    fetchData();
  }, [params]);

  const getLookupOption = (metadata: IMetadata, field: IField): IDropdownOption<any>[] => {
    const lookup = metadata.lookups.filter(l => l.name === field.lookupList)[0];
    if (lookup) {
      const lookupValues = lookup.values;
      return lookupValues.map(v => ({ key: v.key, text: v.value }));
    }
    return [];
  }

  const labelColumnStyle = {
    width: 125,
    textAlign: 'left' as const
  };
  const valueColumnStyle = {
    width: 'auto',
  }
  return (
    <>
      {(!metadata || !values) ? <></> : metadata.fields.map(field => values[field.name] ?
        <Stack horizontal>
          <Label style={labelColumnStyle} disabled={true} >{field.label}</Label>
          {(field.fieldType === "Number" || field.fieldType === "String") ?
            <TextField styles={{ root: { width: '100%' } }}
              type={field.fieldType === "Number" ? "number" : "string"}
              value={values[field.name]}
              readOnly={true} disabled={true} />
            : (field.fieldType === "Lookup") ?
              <Dropdown style={valueColumnStyle} options={getLookupOption(metadata, field)}
                selectedKey={values[field.name]}
                disabled={true} />
              : <></>}
        </Stack> : <></>)}
    </>
  )
}
