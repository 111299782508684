import { Stack, DefaultButton, IContextualMenuItem, IContextualMenuProps, mergeStyleSets } from '@fluentui/react';
import { resources } from '../i18n/config';
import { RenderIf } from '../libs';
import { useConst } from "@fluentui/react-hooks";
import { useTranslation } from 'react-i18next';


const LanguageSelector = () => {
  const languages = (process.env.REACT_APP_LANGS)?.split('|');
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string | undefined) => {
    i18n.changeLanguage(lng);
  };

  const classNames = mergeStyleSets({
    menu: {
      textAlign: 'center',
      maxWidth: 180,
      selectors: {
        '.ms-ContextualMenu-item': {
          height: 'auto',
        },
      },
    },
    item: {
      display: 'inline-block',
      width: 55,
      height: 45,
      lineHeight: 40,
      textAlign: 'center',
      verticalAlign: 'middle',
      marginBottom: 8,
      cursor: 'pointer',
      selectors: {
        '&:hover': {
          backgroundColor: '#eaeaea',
          borderStyle: 'solid',
          borderColor: 'darkgray'
        },
      },
    },
  });

  function renderCharmMenuItem(item: IContextualMenuItem, dismissMenu: () => void): JSX.Element {
    return (
      <img src={resources[item?.key.toString() as keyof typeof resources].flag} onClick={() => changeLanguage(item.key)}
        alt={item?.key.toString()} style={{ width: 50, height: 30, paddingTop: 5, }} />
    );
  }

  const menuProps = useConst<IContextualMenuProps>(() => ({
    shouldFocusOnMount: true,
    items:
      Object.keys(resources).filter(l => languages?.find(sp => sp === l)).map(l => ({
        key: l, text: l.toUpperCase(), onClick: () => changeLanguage(l),
        onRender: renderCharmMenuItem, className: classNames.item,
      }))
  }));

  return (
    <Stack horizontal horizontalAlign="end" grow>
      <RenderIf condition={languages !== undefined && languages.length > 0}>
        <DefaultButton text={i18n.language.toUpperCase()} menuProps={menuProps} />
      </RenderIf>
    </Stack>
  )
}

export default LanguageSelector;